import React from 'react';

import './default.css';


export default function MainPage(props) {
    return (
        <div>
            <h1>Main Page</h1>
        </div>
    );
};

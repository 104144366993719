import React, {useState} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom'
// import {useEffect, useState} from 'react';
import './App.css';
import HamburgerMenu from './source/components/HamburgerMenu'
import NavBar from "./source/components/NavBar";
import MainPage from "./source/pages/MainPage";
import Degrees from "./source/pages/Degrees";
import AboutMe from "./source/pages/AboutMe";
import Projects from "./source/pages/Projects";
import WorkingExperience from "./source/pages/WorkingExperience";
import CoursesAndCertificates from "./source/pages/CoursesAndCertificates";

function App() {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <main className="container">
            <NavBar isOpen={isOpen} setIsOpen={setIsOpen}/>
            <div className="main_content">
                <Switch>
                    <Route path="/" exact>
                        <Redirect to="/About"/>
                    </Route>
                    <Route path="/Degrees" exact>
                        <Degrees/>
                    </Route>
                    <Route path="/About" exact>
                        <AboutMe/>
                    </Route>
                    <Route path="/Projects" exact>
                        <Projects/>
                    </Route>
                    <Route path="/WorkExperience" exact>
                        <WorkingExperience/>
                    </Route>
                    <Route path="/CoursesAndCertificates" exact>
                        <CoursesAndCertificates/>
                    </Route>
                    <Route path="/index">
                        <MainPage/>
                    </Route>
                    <Route path="*">
                        <h1 className="not-found">Not Found!</h1>
                    </Route>
                </Switch>
            </div>
            <HamburgerMenu isOpen={isOpen} setIsOpen={setIsOpen}/>
        </main>
    );
}

export default App;

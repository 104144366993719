import React from 'react';

import './default.css';


export default function WorkingExperience(props) {
    return (
        <div className="timeline fade-in">
            <div className="time-line-card-container left ">
                <div className="content">
                    <h2>DevOps Software Engineer</h2>
                    <h3>Swisscom</h3>
                    <h4>Oct 2021 – Present</h4>
                    <p>Technologies most used during this role:</p>
                    <p className="ascii-art">
                        .<br/>
                        ├── Languages:<br/>
                        │ ├── Golang<br/>
                        │ ├── Python<br/>
                        │ │ ├── FastAPI<br/>
                        │ │ └── pytest<br/>
                        │ ├── Javascript<br/>
                        │ │ ├── Vue.js<br/>
                        │ │ ├── Cypress<br/>
                        │ │ └── TypeScript<br/>
                        ├── DevOps, Cloud, etc..:<br/>
                        │ ├── GIT<br/>
                        │ ├── Makefile<br/>
                        │ ├── Jenkins<br/>
                        │ ├── Helm<br/>
                        │ ├── Kubernetes<br/>
                        │ ├── Fluxcd<br/>
                        │ ├── Istio<br/>
                        │ ├── Postman<br/>
                        │ └──  OpenAPI/Swagger<br/>
                        ├── IDEs I'm most comfortable to use:<br/>
                        │ ├── Jetbrains<br/>
                        │ └── Visual Studio Code(VS Code)<br/>

                    </p>
                </div>
            </div>
            <div className="time-line-card-container left ">
                <div className="content">
                    <h2>Fullstack developer II</h2>
                    <h3>IBM</h3>
                    <h4>Sep 2020 – Oct 2021</h4>
                    <p>Technologies most used during this role:</p>
                    <p className="ascii-art">
                        .<br/>
                        ├── Languages:<br/>
                        │ ├── Python<br/>
                        │ │ ├── Flask<br/>
                        │ │ ├── Celery<br/>
                        │ │ ├── SQLAlchemy<br/>
                        │ │ └── pytest<br/>
                        │ ├── Javascript<br/>
                        │ │ ├── TypeScript<br/>
                        │ │ ├── Angular 9<br/>
                        │ │ └── Jasmine and Karma<br/>
                        │ └── Bash Script<br/>
                        ├── Used in DevOps cycle:<br/>
                        │ ├── GIT with Github<br/>
                        │ ├── Docker<br/>
                        │ ├── TravisCI<br/>
                        │ └── Kubernetes<br/>
                        │ └── Helm<br/>
                        ├── IDEs I'm most comfortable to use:<br/>
                        │ ├── Pycharm IDEA Professional<br/>
                        │ ├── WebStorm IDEA Professional<br/>
                        │ └── Visual Studio Code(VS Code)<br/>
                        ├── Databases, certificates and other technologies:<br/>
                        │ ├── Redis<br/>
                        │ ├── PostgreSQL<br/>
                        │ ├── Postman<br/>
                        │ ├── OpenAPI/Swagger<br/>
                        │ └── RabbitMQ<br/>
                    </p>
                </div>
            </div>
            <div className="time-line-card-container left ">
                <div className="content">
                    <h2>Fullstack developer I</h2>
                    <h3>IBM</h3>
                    <h4>Oct 2019 – Sep 2020</h4>
                    <p>Technologies most used during this role:</p>
                    <p className="ascii-art">

                        .<br/>
                        ├── Languages:<br/>
                        │ ├── Python<br/>
                        │ │ ├── Flask<br/>
                        │ │ ├── Celery<br/>
                        │ │ └── pytest<br/>
                        │ ├── Javascript<br/>
                        │ │ ├── TypeScript<br/>
                        │ │ ├── Angular 9<br/>
                        │ │ └── Jasmine and Karma<br/>
                        │ └── Bash Script<br/>
                        ├── Used in DevOps cycle:<br/>
                        │ ├── GIT with Github<br/>
                        │ ├── Docker<br/>
                        │ ├── TravisCI<br/>
                        │ └── Kubernetes<br/>
                        │ └── Helm<br/>
                        ├── IDEs I'm most comfortable to use:<br/>
                        │ ├── Pycharm IDEA Professional<br/>
                        │ ├── WebStorm IDEA Professional<br/>
                        │ └── Visual Studio Code(VS Code)<br/>
                        ├── DataBase and other technologies:<br/>
                        │ ├── Redis<br/>
                        │ ├── PostgreSQL<br/>
                        │ ├── Postman<br/>
                        │ └── RabbitMQ<br/>
                    </p>
                </div>
            </div>
            <div className="time-line-card-container left ">
                <div className="content">
                    <h2>Fullstack developer</h2>
                    <h3>LAUT</h3>
                    <h4>May 2018 – Oct 2019</h4>
                    <p>Technologies most used during this role:</p>
                    <p className="ascii-art">

                        .<br/>
                        ├── Languages:<br/>
                        │ ├── Java 8<br/>
                        │ │ └── SpringBoot<br/>
                        │ ├── Javascript<br/>
                        │ │ └── Angular JS (1.7)<br/>
                        ├── Used in DevOps cycle:<br/>
                        │ └── GIT with Gitlab<br/>
                    </p>
                </div>
            </div>
            <div className="time-line-card-container left ">
                <div className="content">
                    <h2>Android developer</h2>
                    <h3>Natalnet</h3>
                    <h4>May 2016 – Jan 2017</h4>
                    <p>Technologies most used during this role:</p>
                    <p className="ascii-art">
                        .<br/>
                        ├── Languages:<br/>
                        │ ├── Java 8<br/>
                        │ │ └── Android<br/>
                        ├── Used in DevOps cycle:<br/>
                        │ └── GIT with Gitlab<br/>
                    </p>
                </div>
            </div>
        </div>
    );
};

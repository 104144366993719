import React, {useState} from 'react';
import './default.css';
import loading from "../../../assets/loading.svg";


export default function DegreeItem({title, description, proof, proofURL = false}) {
    const [state, setState] = useState(false)
    const img = new Image()
    img.src = proof

    img.onload = () => setState(true)
    return (
        <div className="degree-item-container fade-in  ">
            <div className="degree-image-container">
                {state && <a href={ proof} rel="noopener noreferrer" target="_blank">
                    <img src={proof} alt="Proof"/>
                </a>}
                {!state && <img src={loading} alt="Proof"/>}
            </div>
            <div className="degree-item__text-container">
                <section>
                    <h1>{title}</h1>
                    <p>{description}</p>
                    {proofURL && <a href={proofURL}>Validate here</a>}
                </section>
            </div>

        </div>
    );
}

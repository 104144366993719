import React, {useState} from 'react';
import resume from '../../../assets/Resume-Victor-Marcolino.pdf'
import './HamburgerMenu.css';
import {NavLink} from "react-router-dom";
import perfil from '../../../assets/perfil.jpg'
import loading from "../../../assets/loading.svg";

function HamburgerMenu({isOpen, setIsOpen}) {
    const [state, setState] = useState(false)
    const img = new Image()
    img.src = perfil
    img.onload = () => setState(true)
    return (
        <div>
            <div className={`hamburger-menu-container ${isOpen ? 'hamburger-menu-container__open' : ''}`}>
                <div className="hamburger-menu__header-container">
                    <div className="hamburger-menu__header-container__item">
                        <a href={state ? perfil : loading}>
                            {state && <img src={perfil} alt="avatar"/>}
                            {!state && <img src={loading} alt="avatar"/>}
                        </a>
                    </div>
                    <div className="hamburger-menu__header-container__text-info">
                        <a href="https://www.linkedin.com/in/victor-marcolino/">Victor Marcolino C. Lima</a><br/>
                        Mechatronics engineer<br/>
                        Fullstack Developer<br/>
                        RHCSA
                    </div>
                </div>
                <div className="hamburger-menu__contents_container">
                    <ul>
                        <li>
                            <NavLink to="About" activeClassName="is--route-active" onClick={() => setIsOpen(false)}>
                                About me
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="WorkExperience" activeClassName="is--route-active"
                                     onClick={() => setIsOpen(false)}>
                                Work experience
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="CoursesAndCertificates" activeClassName="is--route-active"
                                     onClick={() => setIsOpen(false)}>
                                Courses and certificates
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="Degrees" activeClassName="is--route-active" onClick={() => setIsOpen(false)}>
                                Degrees
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="Projects" activeClassName="is--route-active" onClick={() => setIsOpen(false)}>
                                Personal projects
                            </NavLink>
                        </li>
                        <li>
                            <a href={resume} download>
                                Download resume
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="hamburger-menu__footer-container">

                    <a href="https://github.com/MarcolinoProjects" rel="noopener noreferrer" target="_blank"><svg version="1.1" height="32px" width="32px" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px"
                            y="0px"
                            viewBox="0 0 512 512">
                        <g>
                            <g>
                                <path d="M255.968,5.329C114.624,5.329,0,120.401,0,262.353c0,113.536,73.344,209.856,175.104,243.872
			c12.8,2.368,17.472-5.568,17.472-12.384c0-6.112-0.224-22.272-0.352-43.712c-71.2,15.52-86.24-34.464-86.24-34.464
			c-11.616-29.696-28.416-37.6-28.416-37.6c-23.264-15.936,1.728-15.616,1.728-15.616c25.696,1.824,39.2,26.496,39.2,26.496
			c22.848,39.264,59.936,27.936,74.528,21.344c2.304-16.608,8.928-27.936,16.256-34.368
			c-56.832-6.496-116.608-28.544-116.608-127.008c0-28.064,9.984-51.008,26.368-68.992c-2.656-6.496-11.424-32.64,2.496-68
			c0,0,21.504-6.912,70.4,26.336c20.416-5.696,42.304-8.544,64.096-8.64c21.728,0.128,43.648,2.944,64.096,8.672
			c48.864-33.248,70.336-26.336,70.336-26.336c13.952,35.392,5.184,61.504,2.56,68c16.416,17.984,26.304,40.928,26.304,68.992
			c0,98.72-59.84,120.448-116.864,126.816c9.184,7.936,17.376,23.616,17.376,47.584c0,34.368-0.32,62.08-0.32,70.496
			c0,6.88,4.608,14.88,17.6,12.352C438.72,472.145,512,375.857,512,262.353C512,120.401,397.376,5.329,255.968,5.329z"/>
                            </g>
                        </g>
                    </svg></a>
                    <a href="https://www.linkedin.com/in/victor-marcolino" rel="noopener noreferrer" target="_blank"><svg height="32px" viewBox="0 0 512 512" width="32px" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m160.007812 423h-70v-226h70zm6.984376-298.003906c0-22.628906-18.359376-40.996094-40.976563-40.996094-22.703125 0-41.015625 18.367188-41.015625 40.996094 0 22.636718 18.3125 41.003906 41.015625 41.003906 22.617187 0 40.976563-18.367188 40.976563-41.003906zm255.007812 173.667968c0-60.667968-12.816406-105.664062-83.6875-105.664062-34.054688 0-56.914062 17.03125-66.246094 34.742188h-.066406v-30.742188h-68v226h68v-112.210938c0-29.386718 7.480469-57.855468 43.90625-57.855468 35.929688 0 37.09375 33.605468 37.09375 59.722656v110.34375h69zm90 153.335938v-392c0-33.085938-26.914062-60-60-60h-392c-33.085938 0-60 26.914062-60 60v392c0 33.085938 26.914062 60 60 60h392c33.085938 0 60-26.914062 60-60zm-60-412c11.027344 0 20 8.972656 20 20v392c0 11.027344-8.972656 20-20 20h-392c-11.027344 0-20-8.972656-20-20v-392c0-11.027344 8.972656-20 20-20zm0 0"/>
                    </svg></a>
                </div>
            </div>
            <div className={`hamburger-menu-backdrop ${isOpen ? 'hamburger-menu-backdrop__show' : ''}`}
                 onClick={() => setIsOpen(false)}>
            </div>
        </div>
    );
}

export default HamburgerMenu;

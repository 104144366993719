import React from 'react';

import './default.css';
import DegreeItem from "../../components/DegreeItem";
import proof from '../../../assets/diplomacet.jpg'
const degrees =[
    {
        id: 123,
        title: "Bachelor in Science and Technology",
        description: "The Science and Technology course trains professionals with knowledge of Exact and Natural Sciences. Focusing on providing basic knowledge for Engineering and Computing, such as chemistry, advanced physics, advanced mathematics, modeling and computational theory.",
        proof: proof
    }
]
export default function Degrees(props) {
    return (
        <div className="degree-container">
            {degrees.map((item) => <DegreeItem key={item.id} title={item.title} description={item.description} proof={item.proof}>Degrees</DegreeItem>)}
        </div>
    );
};

import React from 'react';

import './default.css';
import RHCSA from "../../../assets/rhcsaproof.jpg";
import RHCSCK from "../../../assets/certs/RHCSCK.png";
import angular from "../../../assets/o6400f7c.bmp";
import w2n4heah from "../../../assets/w2n4heah.bmp";
import nrfmaog9 from "../../../assets/nrfmaog9.bmp";
import c916doba from "../../../assets/c916doba.bmp";
import jkm1j70d from "../../../assets/jkm1j70d.bmp";
import lz7a48ct from "../../../assets/lz7a48ct.bmp";
import exorx4vg from "../../../assets/exorx4vg.bmp";
import docker from "../../../assets/docker.jpg";
import uxwriting from "../../../assets/uxwriting.jpg";
import DegreeItem from "../../components/DegreeItem";

const degrees =[
    {
        id: 1,
        title: "Red Hat Certified Specialist in Containers and Kubernetes",
        description: "A Red Hat Certified Specialist in Containers and Kubernetes has demonstrated a basic understanding of Kubernetes, containers, and Red Hat® OpenShift® and can use this knowledge to run, find, and manage containerized services, deploy single- and multiple-container applications, and create custom containers.",
        proof: RHCSCK,
        proofURL: 'https://rhtapps.redhat.com/certifications/badge/verify/V3WMYQ3VU3SJR74QANJYIWIZO4AEQU3CUPSQX2KSDXT6RW46LQ33TZNCC5VGOAYPFY7HVVIGB5XKUTI5W6QLZX6UMV3D6ILAY7YA4GY='
    },{
        id: 21,
        title: "Red Hat Certified System Administrator(RHCSA)",
        description: "An IT professional who has earned the Red Hat Certified System Administrator (RHCSA®) is able to perform the core system administration skills required in Red Hat Enterprise Linux environments. The credential is earned after successfully passing the Red Hat Certified System Administrator (RHCSA) Exam (EX200).",
        proof: RHCSA,
        proofURL: 'https://rhtapps.redhat.com/certifications/badge/verify/V3WMYQ3VU3SJR74QANJYIWIZO4AEQU3CUPSQX2KSDXT6RW46LQ3T7ULZ55KZZ56SKO7EQ3ETTLYZQ4U5NQYTCNA62RUWOCM34WWBUYQ='
    },
    {
        id: 2,
        title: "Angular - The Complete Guide",
        description: "Deep dive in newest version of Angular framework.",
        proof: angular,
        proofURL: "https://www.udemy.com/certificate/UC-7f8d2f73-254f-4b97-a2dc-2e383b39ad4f/"
    },
    {
        id: 4,
        title: "THE WORLD OF DOCKER & KUBERNETES - CLUSTER SWARM, PROMETHEUS & GRAFANA",
        description: "Fiap is one of the most renowned universities in São Paulo / Brazil in the technological area. " +
            "The course was about Docker, Kubernetes and telemetry.",
        proof: docker,
        proofURL: "https://www2.fiap.com.br/inscricaoworkshopdobem/certificado?chave_=F33F5442-34EE-4FCB-A26D-7D6AB14C0099"
    },
    {
        id: 5,
        title: "OpenShift for the Absolute Beginners - Hands-on",
        description: "",
        proof: w2n4heah,
        proofURL: "https://www.udemy.com/certificate/UC-QKTSPN52/"
    },{
        id: 6,
        title: "Build a Backend REST API with Python & Django - Advanced",
        description: "",
        proof: nrfmaog9,
        proofURL: "https://www.udemy.com/certificate/UC-9KWOM99E/"
    },{
        id: 7,
        title: "Quantitative Finance & Algorithmic Trading in Python",
        description: "",
        proof: c916doba,
        proofURL: "https://www.udemy.com/certificate/UC-3ZQJAS40/"
    },{
        id: 8,
        title: "Artificial Intelligence II - Hands-On Neural Networks (Java)",
        description: "",
        proof: jkm1j70d,
        proofURL: "https://www.udemy.com/certificate/UC-2A1ZV3QR/"
    },{
        id: 9,
        title: "Optimization with Metaheuristics in Python",
        description: "",
        proof: lz7a48ct,
        proofURL: "https://www.udemy.com/certificate/UC-5E92K5NO/"
    },{
        id: 10,
        title: "Introduction to Genetic Algorithms: Theory and Applications",
        description: "",
        proof: exorx4vg,
        proofURL: "https://www.udemy.com/certificate/UC-W2S6J6RN/"
    },{
        id: 3,
        title: "UX WRITING: REESCREVENDO A EXPERIÊNCIA DO USUÁRIO",
        description: "Fiap is one of the most renowned universities in São Paulo/Brazil in the technological area. " +
            "The course was about how to write text and display information on screen to improve the understanding of the user.",
        proof: uxwriting,
        proofURL: "https://www2.fiap.com.br/inscricaoworkshopdobem/certificado?chave_=DE19915B-BA7E-4E3F-BBE0-39F539D5E70E"
    }

]
export default function CoursesAndCertificates(props) {
    return (
        <div className="courses-and-certificates-container">
            {degrees.map((item) => <DegreeItem key={item.id} title={item.title} description={item.description} proof={item.proof} proofURL={item.proofURL}>Degrees</DegreeItem>)}
        </div>
    );
};

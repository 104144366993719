export function get_summary() {
    return [
        "Victor Marcolino is a highly skilled and multi-talented DevOps Software Engineer, currently employed\n" +
        "by Swisscom in Rotterdam, South Holland, Netherlands. Originally from Brazil, Victor is fluent in\n" +
        "both Portuguese and English and holds a Bachelor's degree in Science & Technology, along with a\n" +
        "degree in Mechatronic Engineering from the Federal University of Rio Grande Do Norte.",

        "Victor's extensive professional background includes a notable tenure at IBM as a Full Stack\n" +
        "Developer, where he worked extensively with languages such as Python, JavaScript, TypeScript, and\n" +
        "Bash Script. His experience also encompasses the use of frameworks like Flask, Celery, SQLAlchemy,\n" +
        "    and Angular 9. He has a deep understanding of the DevOps cycle and is proficient in the use of tools\n" +
        "like Git, Docker, TravisCI, Kubernetes, and Helm.",

        "Victor's expertise isn't limited to programming languages and frameworks. He has extensive knowledge\n" +
        "of databases, including Redis, PostgreSQL, and RabbitMQ, and is comfortable using IDEs such as\n" +
        "    Pycharm IDEA Professional, WebStorm IDEA Professional, and Visual Studio Code.",

        "In his current role at Swisscom, Victor has continued to expand his skills set, working with\n" +
        "    technologies like Golang, Python, Javascript, Makefile, Jenkins, Helm, Kubernetes, Fluxcd, Istio,\n" +
        "    and Postman, among others.",

        "Victor also holds numerous certifications including Optimization with Metaheuristics in Python,\n" +
        "    Artificial Intelligence II - Neural Networks in Java, OpenAPI: Beginner to Guru, OpenShift for the\n" +
        "    Absolute Beginners, and THE WORLD OF DOCKER & KUBERNETES - CLUSTER SWARM, PROMETHEUS & GRAFANA. His\n" +
        "achievements include being a finalist and 1st place winner in the Maratona Behind the Code for\n" +
        "    IBMers.",

        "    With a solid foundation in both the practical and theoretical aspects of software engineering,\n" +
        "    Victor\n" +
        "Marcolino exemplifies a committed and innovative professional in the field of DevOps and software\n" +
        "development."

    ]
}

export function get_name() {
    return [
        "Victor Marcolino is a highly skilled and multi-talented DevOps Software Engineer, currently employed\n" +
        "by Swisscom in Rotterdam, South Holland, Netherlands. Originally from Brazil, Victor is fluent in\n" +
        "both Portuguese and English and holds a Bachelor's degree in Science & Technology, along with a\n" +
        "degree in Mechatronic Engineering from the Federal University of Rio Grande Do Norte.",

        "Victor's extensive professional background includes a notable tenure at IBM as a Full Stack\n" +
        "Developer, where he worked extensively with languages such as Python, JavaScript, TypeScript, and\n" +
        "Bash Script. His experience also encompasses the use of frameworks like Flask, Celery, SQLAlchemy,\n" +
        "    and Angular 9. He has a deep understanding of the DevOps cycle and is proficient in the use of tools\n" +
        "like Git, Docker, TravisCI, Kubernetes, and Helm.",

        "Victor's expertise isn't limited to programming languages and frameworks. He has extensive knowledge\n" +
        "of databases, including Redis, PostgreSQL, and RabbitMQ, and is comfortable using IDEs such as\n" +
        "    Pycharm IDEA Professional, WebStorm IDEA Professional, and Visual Studio Code.",

        "In his current role at Swisscom, Victor has continued to expand his skills set, working with\n" +
        "    technologies like Golang, Python, Javascript, Makefile, Jenkins, Helm, Kubernetes, Fluxcd, Istio,\n" +
        "    and Postman, among others.",

        "Victor also holds numerous certifications including Optimization with Metaheuristics in Python,\n" +
        "    Artificial Intelligence II - Neural Networks in Java, OpenAPI: Beginner to Guru, OpenShift for the\n" +
        "    Absolute Beginners, and THE WORLD OF DOCKER & KUBERNETES - CLUSTER SWARM, PROMETHEUS & GRAFANA. His\n" +
        "achievements include being a finalist and 1st place winner in the Maratona Behind the Code for\n" +
        "    IBMers.",

        "    With a solid foundation in both the practical and theoretical aspects of software engineering,\n" +
        "    Victor\n" +
        "Marcolino exemplifies a committed and innovative professional in the field of DevOps and software\n" +
        "development."

    ]
}

export function get_age() {
    const birthDate = new Date("03/03/1995");
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();

    // Adjust the age if the birthday hasn't occurred yet this year.
    if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}

export function get_current_company() {
    return "Swisscom";
}

export function get_current_house() {
    return 'Rotterdam - Netherlands';
}

export function get_full_name() {
    return 'Victor Marcolino Costa de Lima';
}
import React from 'react';
import './default.css';
import {get_age, get_current_company, get_current_house, get_full_name, get_summary} from "../../core/usecases";


export default function AboutMe(props) {
    return (
        <div className="about-container">
            <div className="about-card-container fade-in  ">
                <div className="about-card__header-container">
                    <div className="about-card__header-container-img-container"/>
                    <div className="about-card__header__main-text-container">
                        <h3>{get_full_name()}</h3>
                        <p>Brazilian<br/>
                            {get_age()} years<br/>
                            Currently working for {get_current_company()}<br/>
                            Currently living in {get_current_house()}</p>
                    </div>
                </div>
            </div>

            {get_summary().map((value, index) => {
                return <SummaryCard key={index}>
                    {value}
                </SummaryCard>
            })}
        </div>
    );
};

function SummaryCard({children}) {
    return (
        <div className="about-card-container fade-in  ">
            <div className="about-card__header-container">
                <div className="about-card__header__main-text-container">
                    {children}
                </div>
            </div>
        </div>
    );
};
import React from 'react';

import './default.css';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import controlio from "../../../assets/projects/controlio";
import resume from "../../../assets/projects/resume";

export default function Projects(props) {
    const images_1 = controlio.map((size) => ({
        src: size
    }));
     const images_2 = resume.map((size) => ({
        src: size
    }));
    return (
        <div className="projects-container">
            <div className="projects-card-container fade-in">
                <h1>Personal Projects</h1>
            </div>
            <div className="projects-card-container fade-in  ">
                <div className="projects-card__carousel-container">
                    <Carousel isAutoPlaying={true} images={images_1}/>
                </div>
                <div className="about-card__header__main-text-container">
                    <h3>Controlio</h3>
                    <p>This project offers a way to control microcontrollers like esp8266 through an application</p>
                    <p>It has an API that can interact with the microcontroller passing commands that it receives.</p>
                    <p>Microcontrollers also provide an API that follows a standard. That way, this software can control any microcontroller if it follows the standard when providing an API.</p>
                    <h6>key technologies: React Native, Python, Flask, Celery, PostgresSQL, C++</h6>
                </div>
            </div>
            <div className="projects-card-container fade-in  ">
                <div className="projects-card__carousel-container">
                    <Carousel isAutoPlaying={true} images={images_2}/>
                </div>
                <div className="about-card__header__main-text-container">
                    <h3>Website resume</h3>
                    <p>The objective of this project is to create a place that serves as a showcase for my projects and knowledge.</p>
                    <p>The website is responsive, and follow the concept mobile first.</p>
                    <h6>key technologies: React, CSS, Vercel, Git</h6>
                </div>
            </div>

        </div>
    );
};
